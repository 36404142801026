import React, {useState, useContext} from 'react';
import Page from '../../components/Page/Page';
import { NavLink, useLocation, useHistory, matchPath } from 'react-router-dom';
import { Form, Modal, Radio, Button} from "antd";
import { AuthContext } from '../../App';
import './Pricing.css';
import Checkoutform from './Checkoutform';
import API from '../../utils/API';
import ReactPlayer from "react-player";

const Pricing = (props) => {
  const { state } = useContext(AuthContext);
  const [basic, setbasic] = useState(null);
  const [loading, setloading] = useState(true);
  //const [introVideo, setIntroVideo] = useState(true);
  const [showCheckoutForm, setshowCheckoutForm] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [currentEmail, setcurrentEmail] = useState('');
  const [subStatus, setsubStatus] = useState(null);
  const [subType, setsubType] = useState(null);
  const [periedEnd, setperiedEnd] = useState();
  const [subcriptionId, setsubcriptionId] = useState(null);
  const[customerId, setcustomer] = useState(null)
  const [cancelAt, setcancelAt] = useState();
  const [visiblecancelsub, setVisiblecancelsub] = useState(false);
  const [subid, setsubid] = useState(null);
  const [confirmLoading, setconfirmLoading] = useState(false);
  /* const [chnageToFreeModalVisible, setchnageToFreeModalVisible] = useState(false); */
  const [pid, setPid] = useState('');
  const [price, setprice] = useState('');
  const [planMessage, setplanMessage] = useState('');
  const [changeCardModal, setChangeCardModal]= useState(false);
  const [membership_type, setmemberShipType] = useState(null);
  const [playing, setPlaying] = useState(true);
  const [intensivetrainingStatus, setIntensivetraining] = useState(false)

  //For Live
  const monthlypid = "price_1JXJnlF0dLoHe4JDkckCV2Um";
  const yearlypid = "price_1K56CfF0dLoHe4JDMjGPqRye";
  const  intensivetraining= "price_1NYr5sF0dLoHe4JDnofleBxT";

  let hidemensualpage = false;
  let hideanualpage = false;
  let hideentrenamientopage = false;
  const mensualpage = matchPath(location.search, {
    path: ['?page=mensual'],
    exact: true,
    strict: false
  })
  const anualpage = matchPath(location.search, {
    path: ['?page=anual'],
    exact: true,
    strict: false
  })
  const entrenamientopage = matchPath(location.search, {
    path: ['?page=entrenamiento'],
    exact: true,
    strict: false
  })
  if(mensualpage!=null){
    hideanualpage = true;
    hideentrenamientopage = true;
  }else if(anualpage!=null){
    hidemensualpage = true;
    hideentrenamientopage = true;
  }else if(entrenamientopage!=null){
    hidemensualpage = true;
    hideanualpage = true;
  }
  
  React.useEffect( ()=> {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      let id = JSON.parse(localStorage.getItem('user')).id;
      setcurrentEmail(JSON.parse(localStorage.getItem('user')).email);
      let subemail = JSON.parse(localStorage.getItem('user')).email;
      API.get('users/substate/'+subemail).then(subresult => {
        if(subresult.data != null){
          setIntensivetraining(subresult?.data?.intensivetraining)
          setcustomer(subresult.data.subpay.customer_id)
          setsubStatus(subresult.data.subpay.subscription_status);
          setperiedEnd(subresult.data.subpay.subscription_cancel_at_period_end);
          setsubcriptionId(subresult.data.subpay.subscription_id);

          if(subresult?.data?.subpay?.subscription_type){
            setsubType(subresult?.data?.subpay?.subscription_type);
          }
          let ca = Number(subresult.data.subpay.subscription_cancel_at)*1000;
          const date = new Date(ca);
          const dateNow = new Date();
          const diffTime = Math.abs(date - dateNow);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          setcancelAt(diffDays);
          
        }
        
      }).catch(e =>{
        console.log(e.message);
      });
  }
  },[]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if(value){
      setSubscription(name);
      setbasic(value);
      setloading(false);
      
    }else{
      setbasic(null);
      setloading(true);
    }
  }
  const onFinish = values => {
    /* if(basic){ */
      if(state.isAuthenticated){
        setshowCheckoutForm(true);
      }else{
        //setVisible(true);
        //document.body.classList.add('modalopen');
        //history.push('/login?=/pricing');
        setshowCheckoutForm(true);
      }
      setloading(false);

    /* } */
  }

  /* const handleRedirect =() => {
    setVisible(false);
    history.push('/login?=/pricing');
    document.body.classList.remove('modalopen');
  } */
  const handleCancel = () => {
    setVisible(false);
    setloading(false);
    setVisiblecancelsub(false);
    setsubid(null);
    setconfirmLoading(false);
    //setChangeCardModal(false);
    document.body.classList.remove('modalopen');
  };
  const pageBack = (back) => {
    setshowCheckoutForm(false);
    setloading(true);
    document.body.classList.remove('modalopen');
  }

  /* const chnageToFreeModal = () => {
    setchnageToFreeModalVisible(true);
    setloading(true);
  } */

  /* const chnageToFree = () => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
    document.body.classList.remove('modalopen');

    setconfirmLoading(true);
    let changeemail = JSON.parse(localStorage.getItem('user')).email;
    API.post('users/changetofree', {'email': changeemail}).then(result =>{
      //window.location.reload();
      Modal.success({
        content: 'Te has suscrito con éxito a la membresía gratuita',
        onOk: (e)=> {window.location.reload()}
      });
    })
    .catch()
  } */
  const cancelSubscription = async (subid) => {
    setsubid(subid);
    setVisiblecancelsub(true);
    
  }
  const handleCancelSub = async () => {
    if(subid!=''){
      setconfirmLoading(true)
      const res = await API.post('/users/sub/cancel',
      {'subid': subid});
      if(res.data != null){
        setVisiblecancelsub(false);
        setsubid(null);
        setconfirmLoading(false);
        window.location.reload();
      }
    }
  }
  /* const updatePayment = async (subid) => {
    setChangeCardModal(true);
    
  } */
/*   const confirmChangeCard = async() => {
    setconfirmLoading(true)
    const res = await API.post('/users/create-customer',
      {'subid': customerid});
    if(res?.data?.change){
      setconfirmLoading(true);
      window.location.href = res.data.change;
    }
  } */
  const activeplan = (getpid,getprice, membershiptype, getplanMessage) => {
    document.body.classList.remove('modalopen');
    window.scrollTo({
      top: 0
    });
    setPid(getpid);
    setprice(getprice);
    setplanMessage(getplanMessage);
    if(membershiptype == 'monthly'){
      setSubscription('Membresia básica mensual');
      setmemberShipType('monthly');
    }else if(membershiptype == 'yearly'){
      setSubscription('Membresía  básica anual');
      setmemberShipType('yearly');
    }else if(membershiptype == 'onetime'){
      setSubscription('Entrenamiento Intensivo @$995');
      setmemberShipType('onetime');
    }
  }

  const changePlanCustomer = async() => {
    const res = await API.post('/users/create-customer',
      {'subid': customerId});
    if(res?.data?.change){
      window.location.href = res.data.change;
    }
  }
  /* const handleCancelVideoPopup = () => {
    setIntroVideo(false);
    setPlaying(false);
    localStorage.setItem('introVideoViewed', true);
  }; */

  return (
    <Page>
      
      <div className="maincontent pricing">
      
        {/* <div className="subheading">Lleva la inversión inmobiliaria al siguiente nivel con acceso ilimitado a educación, foros y redes.</div> */}
        <div className="pricelist">
          <h2>LOS MEJORES PLANES DE SUSCRIPCIÓN DE MEMBRESÍA PARA QUE EXPLORES MÁS EN EL MUNDO DE INVERSIONES EN BIENES RAICES</h2>
          {!showCheckoutForm ?
          <div className={state.isAuthenticated ? 'membershipbox three-box': 'membershipbox two-box'}>
            {/* Yearly membership */}
            <div className={"yearlymember membership"+(hideanualpage ? ' hideanualpage':' anualpage')}>
              <div className="popular-tag">Popular</div>
              <Form
                onFinish={onFinish}
              >
                <div className="price">
                {((subStatus === 'active' || subStatus === 'trialing') && subType === 'year')
                  ?<div className="ant-radio-group"><h3 className="active">Membresía <br/> básica anual</h3></div> 
                  :<div className="ant-radio-group"><h3 className="">Membresía <br/> básica anual</h3></div> 
                }
                  <Form.Item>
                    <div className="listpricevalue">$359.88</div>
                    <div className="offerpricevalue">$319.99</div>
                  </Form.Item>
                </div>
                <h4>Membresia incluye: <span className="discount"></span></h4>
                <ul>
                  <li>Acceso a todos los cursos</li>
                  <li>Acceso a todas las clases intensivas en vivo o grabadas</li>
                  <li>Acceso a preguntas y respuestas en vivo o grabadas</li>
                  <li>Busca avanzada de miembros en la plataforma</li>
                  <li>Habilidad de mandar mensajes privados ilimitado a miembros</li>
                  <li>Habilidad de añadir contactos ilimitados</li>
                  <li>¡Acceso a contratos de compra y venta, de renta y mas! (Pronto)</li>
                </ul>
                {((subStatus === 'active' || subStatus === 'trialing') && periedEnd == true && subType === 'year') 
                ? <div>
                    <div className="currentactive">Plan cancelado</div>
                    <div className="cancel-msgsubscription">
                      <span>Aún así, puede hacer uso del servicio durante {cancelAt} día (s).</span>
                    </div>
                  </div>
                :(((subStatus === 'active' || subStatus === 'trialing') && subType === 'year') 
                ? <div>
                    <div className="currentactive">Plan activo actual</div>
                  </div>
                : ((subStatus === 'active' || subStatus === 'trialing')&& subType === 'month') 
                ? <div className="formactions divbutton"><div onClick={() => changePlanCustomer()} className="formsubmit" type="primary" htmlType="submit">Unete Ahora</div></div>
                :<div className="formactions">
                <Button onClick={(e) => activeplan(yearlypid, 'Membresía básica anual $319.99/año','yearly', 'Su plan actual cambiará a membresía anual a $319.99 /año')} className="formsubmit" type="primary" htmlType="submit">Unete Ahora</Button>
              </div> )
                }
              </Form>
            </div>
            {/* Monthly Membership */}
            <div className={"basicmember membership"+(hidemensualpage ? ' hidemensualpage':' mensualpage')}>
              <Form
                onFinish={onFinish}
              >
                <div className="price">
                {((subStatus === 'active'|| subStatus === 'trialing') && subType === 'month')
                  ?<div className="ant-radio-group"><h3 className="active">Membresia <br/> básica mensual</h3></div> 
                  :<div className="ant-radio-group"><h3 className="">Membresia <br/> básica mensual</h3></div> 
                }
                  <Form.Item>
                    <div className="pricevalue">$29.99</div>
                    <label className="labels">Mes</label>
                  </Form.Item>
                </div>
                <h4>Membresia incluye:</h4>
                <ul>
                  <li>Acceso a todos los cursos</li>
                  <li>Acceso a todas las clases intensivas en vivo o grabadas</li>
                  <li>Acceso a preguntas y respuestas en vivo o grabadas</li>
                  <li>Busca avanzada de miembros en la plataforma</li>
                  <li>Habilidad de mandar mensajes privados ilimitado a miembros</li>
                  <li>Habilidad de añadir contactos ilimitados</li>
                  <li>¡Acceso a contratos de compra y venta, de renta y mas! (Pronto)</li>
                </ul>
                {((subStatus === 'active' || subStatus === 'trialing') && periedEnd == true && subType === 'month') 
                ? <div>
                    <div className="currentactive">Plan cancelado</div>
                    <div className="cancel-msgsubscription">
                      <span>Aún así, puede hacer uso del servicio durante {cancelAt} día (s).</span>
                    </div>
                  </div>
                :(((subStatus === 'active'|| subStatus === 'trialing') && subType === 'month') 
                ? <div>
                    <div className="currentactive">Plan activo actual</div>
                  </div>
                : ((subStatus === 'active'|| subStatus === 'trialing') && subType === 'year')
                ? <div className="formactions"><div onClick={() => changePlanCustomer()} className="formsubmit" type="primary">Unete Ahora</div></div>
                : <div className="formactions">
                 <Button onClick={(e) => activeplan(monthlypid, 'Membrecia básica mensual @$29.99/mes', 'monthly', 'Su plan actual cambiará a membresía mensual a $ 29.99 / mes')} className="formsubmit" type="primary" htmlType="submit">Unete Ahora</Button>
               </div> )
                }
              </Form>
            </div>
            {/* <div className={"basicmember membership"+(hideentrenamientopage ? ' hideentrenamientopage':' entrenamientopage')}>
              <Form
                onFinish={onFinish}
              >
                <div className="price">
                {intensivetrainingStatus
                  ?<div className="ant-radio-group"><h3 className="active">Entrenamiento <br/> Intensivo</h3></div> 
                  :<div className="ant-radio-group"><h3 className="">Entrenamiento <br/> Intensivo</h3></div> 
                }
                  <Form.Item>
                    <div className="pricevalue">$995</div>
                    <label className="labels"></label>
                  </Form.Item>
                </div>
                <h4></h4>
                <ul>
                  <li>El entrenamiento intensivo de 6 semanas es un programa completo diseñado para capacitar a emprendedores y entusiastas del mercado inmobiliario en diversos aspectos clave del negocio. Durante estas semanas, los participantes se sumergirán en una amplia variedad de temas que les permitirán adquirir conocimientos y habilidades fundamentales para tener éxito en el mundo de los bienes raíces.</li>
                </ul>
                {intensivetrainingStatus ?
                <></>
                :
                <div className="formactions">
                 <Button onClick={(e) => activeplan(intensivetraining, 'Entrenamiento Intensivo @$995', 'onetime', 'Entrenamiento Intensivo @$995')} className="formsubmit" type="primary" htmlType="submit">Unete Ahora</Button>
               </div>}
              </Form>
            </div> */}
          </div>
           : <div className="pricelist"><Checkoutform pageBack={pageBack} sub={subscription} membership_type={membership_type} defaultemail={currentEmail} pid={pid} price={price} planMessage={planMessage} oldSubscriptionId={subStatus == 'active' ? subcriptionId : ''}/></div>}
        </div>
       
      </div>
      {/* <Modal
        visible={localStorage.getItem('introVideoViewed') ? false : introVideo}
        onCancel={handleCancelVideoPopup}
        centered="true"
        wrapClassName={'testimonialspopup'}
        keyboard={false}
        maskClosable={false}
        footer={null}
      >
      <div className="intro-video-popup">
        <ReactPlayer
            url={'https://vimeo.com/776444600'}
            width="100%"
            height="480px"
            playing={playing}
            controls={true}
            loop={true}
            playsinline = {true}
            light = {true}
        />
        </div>
        </Modal> */}
         <Modal
            visible={visiblecancelsub}
            okText="Yes"
            cancelText="No"
            onOk={handleCancelSub}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
            maskClosable={false}
            confirmLoading={confirmLoading}
         >
            <div>¿Estás segura de que deseas cancelar la suscripción?</div>
         </Modal>
         {/* <Modal
            visible={changeCardModal}
            okText="Continua"
            cancelText="Cancelar"
            onOk={confirmChangeCard}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
            maskClosable={false}
            confirmLoading={confirmLoading}
         >
            <div>You will be redirected to payment partner(<a href="https://stripe.com/" target='_blank'>stripe.com</a>) where you can easily update your card detail and made the newly added card as default payment.
            </div>
         </Modal> */}
    </Page>
  );
};

export default Pricing;