import React, {useState, useContext} from 'react';
import {Form, Input, Card, Button, Modal, Select} from 'antd';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import PhoneInput from 'react-phone-input-2'
import { useHistory } from 'react-router-dom';


// stripe
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Custom Components
import CardInput from './CardInput';

const { Option } = Select;
const stripePromise = loadStripe('pk_live_51JVGVAF0dLoHe4JDdMbF433Fqcb5yWNspDr2d8500x6vCSMIZZLm93nizb5B8oY6cqqJNp2aXxwkUNsaWSxTMxI900cVQRnMda');
const re = /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{8,50}$/;
const alpha = /^(?=.*[A-Za-z ])[a-zA-Z ]{1,50}$/;
const emailcheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const Wrapper = (props) => (
  <Elements stripe={stripePromise}>
    <Checkoutform {...props} />
  </Elements>
);
//const countries = csc.getAllCountries();
function Checkoutform(props) {
  const [form] = Form.useForm()
  const [loading, setloading] = useState(false);
  const [visible, setvisible] = useState(false);
  const { state } = useContext(AuthContext);
  //const [email, setEmail] = useState('');
  const [progresstext, setprogresstext] = useState(null);
  const [processingButton, setprocessingButton] = useState(false);
  const [countryCode, setcountryCode] = useState(null);
  const [stateCode, setstateCode] = useState(null);
  const [fulllnameerror, setfulllnameerror] = useState(false);
  const [addresserror, setaddresserror] = useState(false);
  const [applybuttondisable, setApplyCoupon] = useState(true);
  const [getCouponcode, setCouponcode] = useState(null);
  const [applycouponmsg, setapplycouponmsg] = useState(null);
  const [preregister, setPreregister] = useState(null);
  const [showPop, setshowPop] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const alphanumeric = /^(?=.*[A-Za-z0-9])[a-zA-Z0-9]{1,10}$/;
  const { dispatch } = useContext(AuthContext);
  const [phonenumber, setPhoneNumber] = useState('');
  const [showEmailcheckPop, setshowEmailcheckPop] = useState(false);
  //const [getoldSubscriptionId, setoldSubscriptionId] = useState(null)
  const reloadSame = () => {
    window.location.reload();
  }
  const history = useHistory();

  const handleEmailcheckOk = () => {
    history.push('/login?=/pricing')
  }
  const handleEmailcheckCancel=() =>{
    setloading(false)
    setshowEmailcheckPop(false);
  }
  React.useEffect( ()=> {
    if(props.oldSubscriptionId){
      setshowPop(true);
    }
  },[props.oldSubscriptionId]);

  const coupanvalue = (e) => {
    const couponcode = e.target.value;
    setapplycouponmsg(null);
    if(couponcode == undefined || couponcode == '' || couponcode.trim() == ''){
      setApplyCoupon(true);
      setCouponcode(null);
      //setPreregister(true);
    }else{
      setApplyCoupon(false);
      setCouponcode(couponcode.trim());
    }
  }

  const handleApplyCoupon = async () => {
    const value = form.getFieldsValue();
    if(value.coupon == undefined || value.coupon == '' || value.coupon.trim() == ''){
      setApplyCoupon(true);
      setCouponcode(null);
      return;
    }else{
      setApplyCoupon(false);
      setCouponcode(value.coupon.trim());
      API.get('/users/coupon/'+getCouponcode)
      .then(result => {
        if(result.data.coupon != null ){
          setapplycouponmsg('El código de cupón se aplicó correctamente.');
        }else if(result.data.error){
          if(result.data.error.code == "resource_missing"){
            let aa= '<div className="ant-form-item-has-error"><div className="ant-form-item-explain">Código de cupón inválido</div></div>'
            setapplycouponmsg(aa);
          }
        }
      })
      .catch(e => {
        console.log(e);
      })
    }    
  }

  const handleOk = ()=>{
    setshowPop(false);
  }
  const handlePhoneOnChange = (value, data, event, formattedValue) => {
    if(formattedValue.length > 10){
      setPhoneNumber(formattedValue);
    }else {
      setPhoneNumber('');
    }
  }
  const handleSubmitSub = async (event, product_api_id, preregister_status) => {
    setloading({loading: true})
    let checkValidate = false;
    const value = form.getFieldsValue();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    let getoldSubscriptionId = '';
    let email = props?.defaultemail;
    let firstname = '';
    let lastname = '';
    let password = '';
    if(props?.defaultemail == ''){
      if(value.firstname == undefined || value.firstname == '' || value.firstname.trim() == '' ||
      value.lastname == undefined || value.lastname == '' || value.lastname.trim() == '' || 
      value.email == undefined || value.email == '' || value.email.trim() == '' ||
      value.password == undefined || value.password == '' || value.password.trim() == ''
       ) {
        setloading(false)
        setfulllnameerror(true);
        return;
      }else if(!emailcheck.test(value.email.trim())){
        setloading(false)
        return;
      }else if (!re.test(value.password.trim())){
        setloading(false)
        return;
      }else{
        email = value.email.toLowerCase().trim();
        if(props.membership_type !== 'onetime'){
          const emailcheck = await API.get('users/check-availability/'+email)
          if(emailcheck.data.status){
            setshowEmailcheckPop(true);
            return;
          }
        }
        setfulllnameerror(false);
        firstname = value.firstname.trim();
        lastname = value.lastname.trim();
        password = value.password.trim();
        const subresult = await API.get('users/substate/'+email)
        if(subresult.data?.subpay != null){
          if(subresult.data.subpay.subscription_status == 'active'){
            //setoldSubscriptionId(subresult.data.subpay.subscription_id)
            getoldSubscriptionId = subresult.data.subpay.subscription_id;
          }
        }
        setloading(true)
        checkValidate = true;
      }
    }else if(value.name == undefined || value.name == '' || value.name.trim() == '' ||
    value.postal == undefined || value.postal == '' || value.postal.trim() == '' || !alphanumeric.test(value.postal)) {
      setfulllnameerror(true);
      setloading(false)
      return;
    }else{
      setloading(true)
      setfulllnameerror(false);
      checkValidate = true;
    }
    if(checkValidate){
      //run after validation done
      setvisible(true);
      setprogresstext('Procesando...');
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: value.name,
          email: email,
          address: {
            postal_code: value.postal,
          },
        },
      });
      if (result.error) {
        if(result.error.code == "incomplete_number"){
          setprogresstext("Tu número de tarjeta está incompleto.");
        }else if(result.error.code == "invalid_number"){
          setprogresstext("Tu número de tarjeta no es válido.");
        }else if(result.error.code == "invalid_expiry_year_past"){
          setprogresstext("La fecha de vencimiento de tu tarjeta esta expirada.")
        }else if(result.error.code == "incomplete_cvc"){
          setprogresstext("El código de seguridad de su tarjeta está incompleto.");
        }else if(result.error.code == "incomplete_zip"){
          setprogresstext("El código postal está incompleto.");        
        }else{
          setprogresstext(result.error.message);
        }
        setprocessingButton(true);
        setloading(false);
      } else {
        const res = await API.post('/users/sub',
        {'payment_method': result.paymentMethod.id, 'email': email, 'product_api_id':product_api_id, 'coupon': getCouponcode, 'firstname': firstname, 'lastname': lastname, 'password': password, 'phonenumber': phonenumber});
        // eslint-disable-next-line camelcase
        const {client_secret, status, error, newsubid, customerid} = res.data;
      
        if (status === 'requires_action' || status === 'requires_confirmation') {
          stripe.confirmCardPayment(client_secret).then(async function(result) {
            if (result.error) {
              if(result.error.decline_code == "insufficient_funds"){
                setprogresstext("Su tarjeta no tiene fondos suficientes.");
              }else if(result.error.decline_code == "lost_card"){
                setprogresstext("El pago ha sido rechazado porque se informó que la tarjeta se perdió.");
              }else if(result.error.decline_code == "stolen_card"){
                setprogresstext("El pago ha sido rechazado porque se denuncia el robo de la tarjeta.");
              }else if(result.error.code == "payment_intent_authentication_failure"){
                setprogresstext("No podemos autenticar tu método de pago. Elige otro método y vuelve a intentarlo.");
              }else{
                setprogresstext(error.raw.message);
              }
              
              setprocessingButton(true);
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
            } else {
              //Delete old subscription
              if((props?.oldSubscriptionId != '' && props?.oldSubscriptionId != newsubid) || (getoldSubscriptionId != '' && getoldSubscriptionId != newsubid)){
                let oldsubid = props?.oldSubscriptionId;
                if(getoldSubscriptionId != ''){
                  oldsubid = getoldSubscriptionId;
                }
                if(props.membership_type != 'onetime'){
                  const rescancel = await API.post('/users/sub/delete',{'subid': oldsubid});
                  if(rescancel.data != null){
                  }
                }
              }
            
              setprogresstext('Te has suscrito con éxito a '+props.sub);
              setprocessingButton(true);
              // Show a success message to your customer
              //send data to Refersion
              const script = document.createElement('script');
              let f = '_refersion(function(){ _rfsn._setSource("STRIPE"); _rfsn._addCart("'+customerid+'"); });';
              script.innerHTML = f;
              document.body.appendChild(script);
              //Login the guest user
              if(props?.defaultemail == ''){
                API.post('/users/login', {
                  email: email.toLowerCase().trim(),
                  password: value.password.trim(),
                })
                .then(result => {
                  if(result.data.user!='null'){
                    dispatch({
                      type: 'LOGIN',
                      payload: {
                        token: result.data.token,
                        user: result.data.user,
                      },
                    });
                  }
                });
              }       
            }
          });
        } else if(error) {
          if(error.decline_code == "insufficient_funds"){
            setprogresstext("Su tarjeta no tiene fondos suficientes.");
          }else if(error.decline_code == "lost_card"){
            setprogresstext("El pago ha sido rechazado porque se informó que la tarjeta se perdió.");
          }else if(error.decline_code == "stolen_card"){
            setprogresstext("El pago ha sido rechazado porque se denuncia el robo de la tarjeta.");
          }else if(error.code == "expired_card"){
            setprogresstext("Tu tarjeta ha caducado.");
          }else if(error.code == "incorrect_cvc"){
            setprogresstext("El código de seguridad de su tarjeta es incorrecto.")
          }else if(error.code == "processing_error"){
            setprogresstext("Se produjo un error al procesar su tarjeta. Vuelve a intentarlo en un momento.")
          }else if(error.code == "resource_missing"){
            setprogresstext("Código de cupón inválido");
          }else if(error.code == "coupon_expired"){
            setprogresstext("Este código de cupón ha caducado.");
          }else{
            setprogresstext(error.raw.message);
          }
          setprocessingButton(true);
          console.log(error);
        }else{
          //Delete old subscription
          if((props?.oldSubscriptionId != '' && props?.oldSubscriptionId != newsubid) || (getoldSubscriptionId != '' && getoldSubscriptionId != newsubid)){
            let oldsubid = props?.oldSubscriptionId;
            if(getoldSubscriptionId != ''){
              oldsubid = getoldSubscriptionId;
            }
            if(props.membership_type != 'onetime'){
              const rescancel = await API.post('/users/sub/delete',{'subid': oldsubid});
              if(rescancel.data != null){
              }
            }
          }
          setprogresstext('Te has suscrito con éxito a  '+props.sub);
          setprocessingButton(true);
          //send data to Refersion
          const script = document.createElement('script');
          let f = '_refersion(function(){ _rfsn._setSource("STRIPE"); _rfsn._addCart("'+customerid+'"); });';
          script.innerHTML = f;
          document.body.appendChild(script);
          //Login the guest user
          if(props?.defaultemail == ''){
            API.post('/users/login', {
              email: email.toLowerCase().trim(),
              password: value.password.trim(),
            })
            .then(result => {
              if(result.data.user!='null'){
                dispatch({
                  type: 'LOGIN',
                  payload: {
                    token: result.data.token,
                    user: result.data.user,
                  },
                });
                  //history.push('/pricing');
              }
            });
          }
          //send data to Refersion
          //const script = document.createElement('script');
          /* let price = ''
          if(props.membership_type == 'monthly'){
            price = '29.99';
          }else if(props.membership_type == 'yearly'){
            price = '319.99';
          } */
          /* let f = "_refersion(function(){\
            _rfsn._addTrans({\
                'order_id': '"+newsubid+"',\
                'shipping': '0',\
                'tax': '0',\
                'discount': '0',\
                'discount_code': '',\
                'currency_code': 'USD'\
            });\
            _rfsn._addCustomer({\
                'first_name': '"+state?.user?.firstname+"',\
                'last_name': '"+state?.user?.lastname+"',\
                'email': '"+props?.defaultemail+"',\
                'ip_address': ''\
            });\
            _rfsn._addItem({\
                'sku': '"+props?.membership_type+"',\
                'quantity': '1',\
                'price': '"+price+"'\
            });\
            _rfsn._sendConversion()\
          })"; */
          
          //document.body.removeChild(script);        
        }
      }
      //
    }
    
    
  };
  return (
    <>
    <h3>{props.price}</h3>
    <Card className="card-wrapper">
      <div className="card-container">
       <Form
        initialValues={{ email:props.defaultemail }}
        layout='vertical'
        form={form}
        >
          {fulllnameerror ?
          <div className = "error"><span className="red">*</span>  Este espacio es obligatorio
          </div>
        : null}
        {props?.defaultemail == ''
        ?<><div className='c-box c-box-guest'>
          <div className='c-box-label'>Regístrate y Subscríbete</div>
          <div className='fl-box'>
            <Form.Item
              label="Primer Nombre"
              name="firstname"
              rules={[
                {
                  required: true,
                  //message: 'Entra tu primer nombre',
                  validator: (_, value) => {
                    if(typeof(value) == "undefined" || !value || value.trim() == ''){
                      return Promise.reject(new Error('Entra tu primer nombre'));
                    }else if(value.trim() != ''){
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Solo se permite el alfabeto'));
                    },
                },
              ]}
            >
              <Input  placeholder="Entra tu primer nombre" className="form-text" />
            </Form.Item>
            <Form.Item
              label="Apellido"
              name="lastname"
              rules={[
                {
                  required: true,
                  //message: 'Entra tu apellido',
                  validator: (_, value) => {
                    if(typeof(value) == "undefined" || !value || value.trim() == ''){
                    return Promise.reject(new Error('Entra tu apellido'));
                    }else if(value.trim() != ''){
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Solo se permite el alfabeto'));
                  }
                },
              ]}
            >
              <Input  placeholder="Entra tu apellido" className="form-text" />
            </Form.Item>
          </div>
          <Form.Item
            label="Correo Electronico"
            name="email"
            rules={[
              {
                required: true,
                message: 'Por favor introduzca un correo electrónico válido',
                type: 'email',
              },
            ]}
          >
            <Input  placeholder="Entra tu correo electronico" className="form-text" />
          </Form.Item>
          <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[
                    {
                      required: true,
                      //message: 'La contraseña debe contener letras y numeros con un limite de 8 caracteres',
                      validator: (_, value) => {
                        if(typeof(value) == 'undefined' || !value){
                          return Promise.reject(new Error('La contraseña debe contener letras y numeros con un limite de 8 caracteres'));
                        }else if (!value || re.test(value)) {
                          return Promise.resolve();
                        }
                          return Promise.reject(new Error('La contraseña debe contener letras y numeros con un limite de 8 caracteres'));
                        },
                    },
                  ]}
                >
                  <Input.Password  placeholder="Entra tu contraseña" className="form-text" />
                </Form.Item>
                <div className='ant-row ant-form-item phonebox'>
                  <div className='ant-col ant-form-item-label'>
                    <label className="ant-form-item">Número De Contacto</label>
                  </div>
                  <PhoneInput
                    country={'us'}
                    value={phonenumber}
                    enableSearch={true}
                    onChange={handlePhoneOnChange}
                  />
                </div>

        </div></>
        : null}
        <div className="c-box">
          <div className="c-box-label">Información de facturación</div>
            <Form.Item
              name="name"
              label="Nombre en la tarjeta"
              rules={[
                {
                  required: true,
                  message: 'Se requiere el "nombre"'
                },
              ]}
            >
            <Input placeholder="Entra tu Nombre en la tarjeta" className="form-text"/>
            </Form.Item>
            <CardInput />
            <Form.Item
            name="postal"
            label="Codigo postal"
            rules={[
              {
                required: true,
                 validator: (_, value) => {
                  if(typeof(value) == 'undefined' || !value){
                    return Promise.reject(new Error('Por favor ingrese el código postal'));
                  }else if (!alphanumeric.test(value)) {
                    return Promise.reject(new Error('Caracteres especiales no son permitidos'));
                  }else{
                    return Promise.resolve();
                  }
                    
                  },
              },
            ]}>
              <Input
                id="postal"
                placeholder="12345"
                maxLength="10"
              />
              </Form.Item>           
            <div className="coupon-wrapper csc-wrapper">
            <Form.Item
            name="coupon"
            label="Cupón (opcional)"
            
            >
              <Input className="form-text" onChange={coupanvalue}/>

            </Form.Item>
            <Button 
            className="apply-button"
            disabled={applybuttondisable}
            onClick={handleApplyCoupon}
            >Aplicar cupón</Button>
            </div>
            <div className='couponmsg'>
            <div dangerouslySetInnerHTML={{ __html: applycouponmsg }} /></div>
        </div>
        {/* <div className="c-box">
          <div className="c-box-label">Card Detail</div>
          
        </div> */}
        <div className="actionbutton">
          
          <Button 
          variant="contained" 
          color="primary" 
          className="back"
          disabled={loading}
          onClick= {(e) => {
            props.pageBack(false);
          }}
          >
            atrás
          </Button>

          <Button 
            variant="contained" 
            color="primary" 
            className="subscribe" 
            disabled={loading} 
            onClick={
              (e)=> handleSubmitSub(e,props.pid, preregister)
            }
            >
          Suscribir
          </Button>         
        </div>
        </Form>
        <Modal
          visible={visible}
          okText="Enviar"
          centered="true"
          wrapClassName={'confirmation paymentconfirmation'}
          keyboard="false"
          closable={false}
          footer={null}
        >
          <p>{progresstext}</p>
          
          {processingButton &&
          <Button
              type="primary"
              htmlType="submit"
              className="form-submit"
              onClick={reloadSame}
            >
              ok
            </Button>
            }
        </Modal>
        <Modal
            visible={showPop}
            okText="Continue"
            cancelText="Atrás"
            onOk={handleOk}
            onCancel={reloadSame}
            keyboard="false"
            centered="true"
            closable={false}
            maskClosable={false}
         >
            <div>{props.planMessage}</div>
         </Modal>
         <Modal
            visible={showEmailcheckPop}
            okText="Iniciar sesión"
            cancelText="Atrás"
            onOk={handleEmailcheckOk}
            onCancel={handleEmailcheckCancel}
            keyboard="false"
            centered="true"
            closable={false}
            maskClosable={false}
         >
            <div>Correo electrónico ya en uso</div>
         </Modal>
      </div>
    </Card>
    </>
  );
}

export default Wrapper;
